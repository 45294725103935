var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',{ref:"panel"},[_c('v-expansion-panel-header',{staticClass:"pa-2"},[_c('v-row',{attrs:{"dense":"","align":"center","justify":"center"}},[_c('v-col',{staticClass:"d-flex justify-center pa-2",attrs:{"cols":"2","align-self":"center"}},[_c('v-img',{staticClass:"pa-0",attrs:{"contain":"","src":_vm.thumbnailSrc,"max-width":_vm.$vuetify.breakpoint.smAndDown ? 75 : 100,"max-height":_vm.$vuetify.breakpoint.smAndDown ? 75 : 100},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('showImage', _vm.item.image_data)}}})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-row',{staticClass:"display-flex align-start justify-start my-2",attrs:{"dense":""}},[_c('v-col',{staticClass:"text-xs-left"},[_c('CiHeading',{attrs:{"item":_vm.item}}),_c('p',{staticClass:"caption body-1 pa-2",domProps:{"innerHTML":_vm._s(_vm.item.description)}})],1)],1),_c('v-row',{staticClass:"my-2",attrs:{"dense":"","align":"center","justify":"start"}},[_c('v-tooltip',{attrs:{"color":"primary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"text":"","color":_vm.status.color},on:{"click":function($event){$event.stopPropagation();return _vm.reserve(_vm.item)}}},on),[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s(_vm.status.icon)}}),_vm._v(" "+_vm._s(_vm.status.text)+" ")],1)]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.status.popovertext)}})]),_c('v-tooltip',{attrs:{"color":"primary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"text":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.showReservations($event)}}},on),[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-history')}}),_vm._v(" "+_vm._s(_vm.item.lastReservation ? _vm.formatDate(_vm.item.lastReservation.start_date, true, false) : 'N/A')+" ")],1)]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.lastReservedText())}})])],1)],1)],1)],1),_c('v-expansion-panel-content',[_c('v-row',{attrs:{"dense":"","justify":"end"}},[_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{staticClass:"d-flex align-end justify-space-between",attrs:{"cols":"10"}},[_c('v-sheet',{staticClass:"d-flex flex-column align-start justify-start"},[_c('p',[_c('span',{staticClass:"text-body-1 font-weight-bold primary--text"},[_vm._v(" ITEM VISIBILITY: ")]),_vm._v(" "+_vm._s(_vm.item.internal === '0' ? 'Publicly listed' : 'Internal use')+" ")]),_c('p',[_c('span',{staticClass:"text-body-1 font-weight-bold primary--text"},[_vm._v(" RESERVATION LENGTH: ")]),_vm._v(" "+_vm._s(_vm.reservationText(_vm.item.reservation_length))+" ")]),_c('p',[_c('span',{staticClass:"text-body-1 font-weight-bold primary--text"},[_vm._v(" RESERVATION BUFFER: ")]),_vm._v(" "+_vm._s(_vm.reservationText(_vm.item.reservation_buffer))+" ")])]),_c('v-spacer'),_c('v-sheet',[_c('v-tooltip',{attrs:{"color":"primary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"primary"},on:{"click":_vm.showNotes}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-note-text-outline")])],1)]}}])},[_c('span',[_vm._v("Notes")])]),_c('v-tooltip',{attrs:{"color":"primary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":_vm.showReservations}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-calendar-clock")])],1)]}}])},[_c('span',[_vm._v("View Reservations")])]),_c('v-tooltip',{attrs:{"color":"primary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"primary"},on:{"click":_vm.edit}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-square-edit-outline")])],1)]}}])},[_c('span',[_vm._v("Edit Item")])])],1)],1)],1),_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"10","offset":"2"}},[_c('v-divider'),_c('p',{staticClass:"title font-weight-bold primary--text"},[_vm._v("DETAILS")])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"8","offset":"2"}},[_c('customFieldsList',{attrs:{"items":_vm.item.customFields}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }